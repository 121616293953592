import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ShopCategory from './Pages/ShopCategory';
import Accueil from './Pages/Accueil';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import LoginSignup from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
import men_banner from './Components/Assets/banner_mens.png'
import women_banner from './Components/Assets/banner_women.png'
import kid_banner from './Components/Assets/banner_kids.png'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Accueil/>} />
          <Route path="/chat" element={<ShopCategory banner={men_banner} category="chat"/>} />
          <Route path="/chien" element={<ShopCategory banner={women_banner} category="chien"/>} />
          <Route path="/poissons" element={<ShopCategory banner={kid_banner} category="poissons"/>} />
          <Route path="/oiseaux" element={<ShopCategory banner={men_banner} category="oiseaux"/>} />

          <Route path="/product" element={<Product/>}>
            <Route path=":productId" element={<Product/>} />
          </Route>
          <Route path="/cart" element={<Cart/>} />
          <Route path="/login" element={<LoginSignup/>} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
      
    </div>
  );
}

export default App;
