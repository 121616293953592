import React from 'react'
import './Footer.css'
import ns_animaux_icon from '../Assets/ns_animaux_icon.png'
import instagram_icon from '../Assets/instagram_icon.png'
import facebook_icon from '../Assets/facebook.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={ns_animaux_icon} alt="" />
            <p>NS Animaux</p>
        </div>
        <ul className="footer-links">
            <li>Animalerie</li>
            <li>Produits</li>
            <li>Adresse</li>
            <li>A propos</li>
            <li>Contact</li>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <Link to="https://www.facebook.com/NSanimaux" target='_blank'>
                    <img src={facebook_icon} alt=''  />
                </Link>
            </div>
            <div className="footer-icons-container">
                <Link to="https://www.instagram.com/ns.animaux/" target='_blank'>
                    <img src={instagram_icon} alt=''  />
                </Link>
            </div>
            
        </div>
        <div className="footer-copyright">
            <hr />
            <p>Copyright © 2024 - All rights reserved</p>
            <p>Created by  
                <Link to="https://aymenmohsni.netlify.app" target='_blank'> Aymen Mohsni</Link> 
            </p>
        </div>
    </div>
  )
}

export default Footer