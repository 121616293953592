import React, { useContext, useRef, useState } from 'react'
import './Navbar.css'

import cart_icon from '../Assets/cart_icon.png'
import { Link } from 'react-router-dom'
import { ShopContext } from '../../Context/ShopContext'
import chevron from '../Assets/chevron.png'
import ns_animaux_icon from '../Assets/ns_animaux_icon.png'

const Navbar = () => {
    const [menu,setMenu]=useState("accueil");
    const {getTotalCartItems} = useContext(ShopContext);
    const menuRef = useRef();

    const dropdown_toggle = (e) =>{
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open')
    }

  return (
    <div className='navbar'>
        <div className="nav-logo">
            <img src={ns_animaux_icon} alt="" />
            <p>NS Animaux</p>
        </div>
        <img className='nav-dropdown' onClick={dropdown_toggle} src={chevron} alt="" />
        <ul ref={menuRef} className="nav-menu">
            <li onClick={()=>{setMenu("accueil")}}><Link style={{textDecoration:'none'}} to="/">Accueil</Link>{menu==="accueil"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("chat")}}><Link style={{textDecoration:'none'}} to="/chat">Chat</Link>{menu==="chat"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("chien")}}><Link style={{textDecoration:'none'}} to="/chien">Chien</Link>{menu==="chien"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("Poissons")}}><Link style={{textDecoration:'none'}} to="/Poissons">Poissons</Link>{menu==="Poissons"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("oiseaux")}}><Link style={{textDecoration:'none'}} to="/oiseaux">Oiseaux</Link>{menu==="oiseaux"?<hr/>:<></>}</li>
        </ul>
        <div className="nav-login-cart">
            <Link to="/login"><button>Login</button></Link>
            <Link to="/cart"><img src={cart_icon} alt="" /></Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div>
    </div>
  )
}

export default Navbar